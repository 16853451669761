@import "./variables.scss";

body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $Navy;
  color: $Slate !important;
  line-height: 1.3;
  font-family: $MainFont;
  font-size: 20px;
}

p {
  font-size: 1.2rem;
}

.image-container {
  width: 100%;

  @media (min-width: 1024px) {
    width: 35%;
    margin-bottom: 0; // lg:mb-0
  }
}

.alert-text {
  color: red !important;
}

.subtitle {
  text-align: justify;
}

.strong-text {
  font-weight: bolder;
}

.section-box {
  padding: 50px 0;
}

.code-font {
  font-family: $CodeFont;
}

.e-font {
  font-family: $MainFont;
}

.section-title {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px 0 40px;
  width: 100%;
  white-space: nowrap;
  font-size: 32px;
  font-weight: 600;
  color: $LightestSlate;
  font-family: $MainFont;
  margin: 0 0 10px 0;

  .n-section-title {
    margin-right: 10px;
    font-family: $CodeFont;
    font-weight: normal;
    color: $Green;
    font-size: 24px;
    position: relative;
  }

  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 300px;
    background-color: $LightestNavy;
    position: relative;
    top: 0px;
    margin-left: 20px;
  }
}

.main-btn {
  color: $Green;
  background-color: transparent;
  border: 1px solid $Green;
  border-radius: 3px;
  font-size: 13px;
  font-family: $CodeFont;
  text-decoration: none;
  line-height: 1;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 1.25rem 1.75rem;

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(100, 255, 218, 0.07);
    outline: none;
  }
}

input {
  border-radius: 0.5rem;
  border: none;
  text-align: center;
  background-color: #28a7469d;
}

.green-text {
  color: #4CAF4F;
  font-weight: 500;
  font-size: 20px;
}

.primary-btn {
  color: $Green !important;
  background-color: $LightGreen !important;
  border: 1px solid $Green !important;
  border-radius: 1rem !important;
  font-size: 13px !important;
  line-height: 1 !important;
  font-weight: 700 !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer !important;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 1rem;

  &:hover,
  &:focus,
  &:active {
    background-color: $Green !important;
    color: $White !important;
    outline: none !important;
    text-decoration: none !important;
  }

  @media (max-width: 750px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1100px) {
    text-align: center;
  }

}

.second-btn {
  color: $White;
  background-color: rgba(76, 167, 113, 1);
  border-radius: 21px;
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  -webkit-text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  padding: 1rem 2rem;

  &:hover,
  &:focus,
  &:active {
    background-color: $White;
    color: $Green;
    border: 1px solid $Green;
    text-decoration: none;
  }

  @media (max-width: 1100px) {
    text-align: center;
  }
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  margin-bottom: 10px !important;
}

.third-btn {
  color: white;
  background-color: rgba(15, 23, 42, 1);
  border-radius: 21px;
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  -webkit-text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  padding: 1rem 2rem;

  &:hover,
  &:focus,
  &:active {
    background-color: $Green;
    color: $White;
    outline: none;
    border: none;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $Green;
  }
}

.display-left {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.underline {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: $Green !important;
}

.underline:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 3px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: $Green;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0%;
}

.underline:hover:after {
  width: 100%;
  left: 0;
}

@media (min-width: 768px) and (max-width: 1100px) {
  .proyect-container {
    margin: 30px 0 !important;
    height: 385px !important;
  }
}


@media (min-width: 1500px) {
  .container {
    max-width: 90% !important;
  }

  p {
    font-size: 30px !important;
  }

  h2 {
    font-size: 40px !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1000px;
  }

  p {
    font-size: 18px !important;
  }
}

@media (min-width: 880px) and (max-width: 992px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 768px) and (max-width: 880px) {
  .container {
    max-width: 600px;
  }
}

@media (max-width: 768em) {
  .section-title {
    font-size: 18px;
  }

  .container {
    width: 90%;
  }
}

@media (max-width: 1000px) {

  .banner h2,
  .banner h3,
  .big-title {
    font-size: 70px !important;
  }

  .big-subtitle {
    font-size: 14px !important;
  }
}

@media (max-width: 760px) {

  h2 {
    font-size: 28px !important;
    font-weight: 500;
  }

  h3 {
    font-size: 16.41px !important;
    margin-bottom: 0rem !important;
    font-weight: 400 !important;
  }

  label {
    font-size: 13px;
    font-weight: 400;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

}

@media (max-width: 480px) {

  .banner h2,
  .banner h3,
  .big-title {
    font-size: 38px !important;
  }
}

@media (max-width: 376px) {

  .banner h2,
  .banner h3,
  .big-title {
    font-size: 22px !important;
  }
}

@media (max-width: 30em) {
  body {
    font-size: 18px;
  }
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}