// @font-face {
//     font-family: 'Calibre';
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('Calibre'),
//          url('assets/fonts/Calibre/Calibre-MediumItalic.woff2') format('woff2'),
//          url('assets/fonts/Calibre/Calibre-MediumItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Calibre';
//     font-weight: 600;
//     font-style: italic;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('Calibre'),
//          url('assets/fonts/Calibre/Calibre-SemiboldItalic.woff2') format('woff2'),
//          url('assets/fonts/Calibre/Calibre-SemiboldItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Calibre';
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('Calibre'),
//          url('assets/fonts/Calibre/Calibre-Regular.woff2') format('woff2'),
//          url('assets/fonts/Calibre/Calibre-Regular.woff') format('woff');
// }

// @font-face {
//     font-family: 'Calibre';
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('Calibre'),
//          url('assets/fonts/Calibre/Calibre-Medium.woff2') format('woff2'),
//          url('assets/fonts/Calibre/Calibre-Medium.woff') format('woff');
// }

// @font-face {
//     font-family: 'Calibre';
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('Calibre'),
//          url('assets/fonts/Calibre/Calibre-Semibold.woff2') format('woff2'),
//          url('assets/fonts/Calibre/Calibre-Semibold.woff') format('woff');
// }

// @font-face {
//     font-family: 'Calibre';
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('Calibre'),
//          url('assets/fonts/Calibre/Calibre-RegularItalic.woff2') format('woff2'),
//          url('assets/fonts/Calibre/Calibre-RegularItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'Calibre';
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('Calibre'),
//          url('assets/fonts/Calibre/Calibre-Light.woff2') format('woff2'),
//          url('assets/fonts/Calibre/Calibre-Light.woff') format('woff');
// }

// @font-face {
//     font-family: 'Calibre';
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('Calibre'),
//          url('assets/fonts/Calibre/Calibre-LightItalic.woff2') format('woff2'),
//          url('assets/fonts/Calibre/Calibre-LightItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'SF Mono';
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('SF Mono'),
//          url('assets/fonts/SFMono/SFMono-Regular.woff2') format('woff2'),
//          url('assets/fonts/SFMono/SFMono-Regular.woff') format('woff');
// }

// @font-face {
//     font-family: 'SF Mono';
//     font-weight: 600;
//     font-style: italic;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('SF Mono'),
//          url('assets/fonts/SFMono/SFMono-SemiboldItalic.woff2') format('woff2'),
//          url('assets/fonts/SFMono/SFMono-SemiboldItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'SF Mono';
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('SF Mono'),
//          url('assets/fonts/SFMono/SFMono-MediumItalic.woff2') format('woff2'),
//          url('assets/fonts/SFMono/SFMono-MediumItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'SF Mono';
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('SF Mono'),
//          url('assets/fonts/SFMono/SFMono-RegularItalic.woff2') format('woff2'),
//          url('assets/fonts/SFMono/SFMono-RegularItalic.woff') format('woff');
// }

// @font-face {
//     font-family: 'SF Mono';
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('SF Mono'),
//          url('assets/fonts/SFMono/SFMono-Medium.woff2') format('woff2'),
//          url('assets/fonts/SFMono/SFMono-Medium.woff') format('woff');
// }

// @font-face {
//     font-family: 'SF Mono';
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
//     unicode-range: U+000-5FF;
//     src: local('SF Mono'),
//          url('assets/fonts/SFMono/SFMono-Semibold.woff2') format('woff2'),
//          url('assets/fonts/SFMono/SFMono-Semibold.woff') format('woff');
// }

@font-face {
    font-family: 'Kabel LT Std Black';
    src: url('assets/fonts/kabel-lt-std-black-maisfontes.e122/kabel-lt-std-black.otf') format('otf');
    /* OpenType format */
    // src: url('assets/fonts/Kabel\ LT\ Std\ Black/!important');
    font-weight: normal;
    font-style: normal;
}