/* You can add global styles to this file, and also import other style files */

$Navy : #D9D9D987;
$LightNavy : #112240;
$LightestNavy : #303C55;
$Slate : #000000;
//$Slate : #ffffff;
$LightSlate : #4CAF4F0A;
$Black: #000000;
$LightestSlate: #000000;
$White : #ffffff;
$Yellow: #CAAA5F;
// $Green : #009438;
$Green : rgba(22, 147, 111, 1);
$Blue : #124B60;
$LightGreen: #43ff6408;
$GreenOpacity : rgba(100, 255, 218, 0.07);

// $MainFont: Calibre, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;
$MainFont: 'Kabel LT Std Black', Calibre, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;
// $CodeFont: SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, Monospace;
$CodeFont: 'Kabel LT Std Black', Calibre, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;